<template>
  <div class="ranking-design hidden-scrollbar">
    <div class="wrapper">
      <div class="header">
        <img src="/static/images/design-header.png" alt="" />
      </div>
      <p class="f-s-20 text-c subtitle">第三季度</p>
      <div
        class="box"
        v-for="(item, index) in dataList"
        :key="index"
        v-show="dataList.length > 0"
      >
        <div class="title text-c m-b-30">
          <span>{{ item.rank_type_name }}</span>
        </div>
        <div
          class="box-item"
          v-for="(el, ids) in item.rank_list"
          :key="'item:' + ids"
        >
          <div v-if="!(item.rank_type % 2)" class="user">
            <div class="pic">
              <img :src="el.header_image" alt="" />
            </div>
            <p>{{ el.name }}</p>
            <p>{{ el.org_name }}</p>
            <div class="flower"></div>
          </div>
          <div class="box-wrap">
            <div class="pic">
              <img :src="el.cover_file" alt="" />
            </div>
            <p>
              <span class="bold">作品名称：</span>
              <span>{{ el.title }}</span>
            </p>
            <div class="num">{{ el.rank_name }}</div>
          </div>
          <div v-if="item.rank_type % 2" class="user">
            <div class="pic">
              <img :src="el.header_image" alt="" />
            </div>
            <p>{{ el.name }}</p>
            <p>{{ el.org_name }}</p>
            <div class="flower"></div>
          </div>
        </div>
      </div>
      <div class="none" v-if="dataList.length == 0">
        <p>暂无数据</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { Store } from "@/store/";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store: Store = useStore();
    const { userid, username, dept_id } = store.state.app;

    const dataMap = reactive({
      dataList: [] as any[],
    });

    const activityDataList = () => {
      store
        .dispatch("actActivityDataList", {
          userid: userid,
          username: username,
          dept_id: dept_id,
          option: route.query.option as string,
        })
        .then((res) => {
          if (res.status == 200) {
            dataMap.dataList = res.data_list;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    activityDataList();
    return {
      ...toRefs(dataMap),
    };
  },
});
</script>

<style lang="less" scoped>
.ranking-design {
  width: 375px;
  height: 100vh;
  overflow: auto;
  background-image: url("/static/images/design-bg.jpg");
  background-size: 375px 100%;
  background-repeat: no-repeat;
  padding: 20px 20px;

  .wrapper {
    border: 2px solid #f9e9aa;
    padding: 0 5px 30px;
    border-radius: 10px;
    .header {
      img {
        width: 375px;
      }
    }
    .subtitle {
      color: #fff;
      margin: -40px 0 20px;
    }
    .none {
      min-height: calc(100vh - 375px);
      p {
        text-align: center;
        color: #fff;
        font-size: 18px;
        padding-top: 25px;
        font-weight: 700;
      }
    }
  }
  .box {
    & + .box {
      margin-top: 25px;
    }
    .title {
      span {
        display: inline-block;
        font-size: 22px;
        font-weight: 800;
        padding: 10px 20px;
        color: #9a8e21;
        background-color: #fff;
        border-radius: 10px;
      }
    }
    .box-item {
      display: flex;
      flex-wrap: nowrap;
      .box-wrap {
        flex: 0 0 70%;
        color: #fff;
        font-weight: 800;
        font-size: 12px;
        position: relative;
        .pic {
          text-align: center;
          img {
            width: 180px;
            height: 120px;
            background-color: rgba(0, 0, 0, 0.2);
            object-fit: contain;
          }
        }
        > p {
          padding-left: 40px;
          .bold {
            font-size: 18px;
          }
        }
        .num {
          position: absolute;
          right: -5px;
          top: -25px;
          font-size: 36px;
        }
      }
      .user {
        flex: 0 0 30%;
        text-align: center;
        color: #fff;
        font-weight: 800;
        font-size: 16px;
        position: relative;
        .pic {
          margin-bottom: 15px;
          img {
            width: 80px;
            height: 80px;
            border-radius: 50%;
          }
        }
        .flower {
          width: 40px;
          height: 60px;
          position: absolute;
          background-image: url("/static/images/flower.png");
          background-size: 40px 60px;
          background-repeat: no-repeat;
          top: 50px;
          left: calc(50% + 2.5px);
        }
      }
      & + .box-item {
        padding-top: 25px;
      }
    }
  }
}
</style>